:root,
[data-theme="default"] {
  /* Theme colors */
  --primary: #023047;
  --secondary: #fb8500;
  /* System colors */
  --info: #276db5;
  --success: #016529;
  --error: #bc0505;
  --warning: #e89806;
}
