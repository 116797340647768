@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vars";

@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap");

html {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  // Remove. This is just an example.
  @apply bg-primary;
  color: var(--primary);
}

body {
  @apply font-body;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 18px;
  font-style: normal;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-display;
}
